// BookingDaysRows.tsx
import React from 'react';
import { ClientLayout, Calendar, Booking } from '../models/ClientLayoutModel';
import { isBookingOnDate } from '../helpers/DateHelper';
import { getFieldValue } from '../helpers/Utils';

interface RowsWithTilesProps {
    data: ClientLayout;
    scrollRef: React.RefObject<HTMLDivElement>;
}

const RowsWithTiles: React.FC<RowsWithTilesProps> = ({ data, scrollRef }) => {
    // Utility function to generate date range
    const getDateRange = (dateRangeBefore: number, dateRangeAfter: number): string[] => {
        const today = new Date();
        const startDate = new Date(today);
        startDate.setDate(today.getDate() - dateRangeBefore);
        const endDate = new Date(today);
        endDate.setDate(today.getDate() + dateRangeAfter);

        const dates: string[] = [];
        let currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            dates.push(currentDate.toISOString().split('T')[0]); // Format YYYY-MM-DD
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    };

    // Function to render calendar header based on rowHeaderDatasetType
    const renderHeader = (date: string) => {
        const dayOfWeekCapitalized =
            new Date(date).toLocaleDateString('da-DK', { weekday: 'long' }).charAt(0).toUpperCase() +
            new Date(date).toLocaleDateString('da-DK', { weekday: 'long' }).slice(1);
        const dateFormatted = new Date(date).getDate() + '/' + (new Date(date).getMonth() + 1);

        return (
            <div className="day-header" style={JSON.parse(data.style.dayHeaderCss ?? "{}")}>
                <div className="day-of-week" style={JSON.parse(data.style.dayHeaderDayOfWeekCss ?? "{}")}>
                    {dayOfWeekCapitalized}
                </div>
                <div className="date-formatted" style={JSON.parse(data.style.dayHeaderDateCss ?? "{}")}>
                    {dateFormatted}
                </div>
            </div>
        );
    };

    // Function to render booking content based on tileDatasetType
    const renderTileContent = (date: string, calendarId: number = 0) => {
        const tilesPerRow = data.style.bookingTilesPerRow ?? 5;
        const tilesForDate = data.bookings.filter(booking => {
            if (calendarId !== 0)
                return booking.calendarId === calendarId && isBookingOnDate(new Date(booking.start), new Date(booking.end), date)
            else return isBookingOnDate(new Date(booking.start), new Date(booking.end), date)
        })

        switch (data.style.tileDatasetType) {
            case "Booking":
                return (

                    <div key={date} className="date-container">
                        {renderHeader(date)}

                        <div className="bookings">
                            {tilesForDate.reduce((rows: Booking[][], booking, index) => {
                                if (index % tilesPerRow === 0) {
                                    rows.push([]); // Start a new row
                                }
                                rows[rows.length - 1].push(booking);
                                return rows;
                            }, []).map((row, rowIndex) => {
                                const blankCount = tilesPerRow - row.length;
                                const tileWidth = 1 / tilesPerRow * 100
                                return (
                                    <div key={rowIndex} className="booking-row">
                                        {row.map((booking, index) => {
                                            let startTime: Date, endTime: Date;

                                            const bookingStartDate = new Date(booking.start).toISOString().split('T')[0]; // YYYY-MM-DD
                                            const bookingEndDate = new Date(booking.end).toISOString().split('T')[0]; // YYYY-MM-DD

                                            // Apply the logic to determine the correct times
                                            if (bookingStartDate === date && bookingEndDate !== date) {
                                                // First day: use the actual start time and set the end time to 23:59
                                                startTime = new Date(booking.start);
                                                endTime = new Date(booking.start);
                                                endTime.setHours(23, 59);
                                            } else if (bookingStartDate !== date && bookingEndDate === date) {
                                                // Last day: set the start time to 00:00 and use the actual end time
                                                startTime = new Date(booking.end);
                                                startTime.setHours(0, 0);
                                                endTime = new Date(booking.end);
                                            } else if (bookingStartDate !== date && bookingEndDate !== date) {
                                                // Intermediate days: set start time to 00:00 and end time to 23:59
                                                startTime = new Date(date); // current date
                                                startTime.setHours(0, 0);
                                                endTime = new Date(date); // current date
                                                endTime.setHours(23, 59);
                                            } else {
                                                // Single day booking
                                                startTime = new Date(booking.start);
                                                endTime = new Date(booking.end);
                                            }

                                            return (
                                                <div
                                                    key={booking.id}
                                                    className="booking-box"
                                                    style={{
                                                        ...((index + 1) % 2 === 0 ? JSON.parse(data.style.oddsCss ?? "{}") : JSON.parse(data.style.evensCss ?? "{}")),
                                                        ...JSON.parse(data.style.bookingContainerCss ?? "{}"),
                                                        width: `${tileWidth}%`  // Append calculated width property
                                                    }}
                                                >
                                                    {
                                                        data.fieldNamesToDisplay.map((field) => (
                                                            <div key={field.sourceType + field.fieldName} className="table-cell" style={JSON.parse(field.valueFieldCss ?? "{}")}>
                                                                {getFieldValue(
                                                                    booking,
                                                                    field,
                                                                    data.calendars.find(c => c.id === booking.calendarId) ?? {} as Calendar // The last part satisfies the linter
                                                                )}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            );

                                        })}

                                        {/* Add blank slots with the same style as evens */}
                                        {[...Array(blankCount)].map((_, index) => (
                                            <div
                                                key={`blank-${date}-${rowIndex}-${index}`}
                                                className="booking-box"
                                                style={{
                                                    ...JSON.parse(data.style.evensCss ?? "{}"),
                                                    ...JSON.parse(data.style.bookingContainerCss ?? "{}"),
                                                    width: `${tileWidth}%`
                                                }}
                                            ></div>
                                        ))}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            default:
                return <div>NOT YET IMPLEMENTED</div>;
        }
    };

    // Main rendering logic
    const renderCalendars = (calendar: Calendar) => {
        const dateRange = getDateRange(data.dateRangeBeforeToday, data.dateRangeAfterToday);

        return (
            <div className="calendar-container">
                {<div className="calendar-header" style={JSON.parse(data.style.calendarTitleCss ?? "{}")}>
                    {calendar.name}
                </div>
                }
                {dateRange.map((date) => {
                    return (
                        renderTileContent(date, calendar.id)
                    );
                })}
            </div>
        );
    };
    const dateRange = getDateRange(data.dateRangeBeforeToday, data.dateRangeAfterToday);
    return (
        <div className={data.style.autoscrollOnVerticalOverflow ? "scrollable-content" : "{}"} ref={scrollRef} style={JSON.parse(data.style.backgroundCss ?? "{}")}>
            <div style={JSON.parse(data.style.layoutNameCss ?? "{}")}>{data.layoutName}</div>
            {!data ? (<div> No data available</div>) :
                data.calendars.length === 0 ? (<div>No calendars available.</div>) :
                    !data.style.mergeBookingsIntoSingleCalendarView ? (
                        data.calendars.map((calendar) => renderCalendars(calendar))
                    ) : (
                        dateRange.map((date) => renderTileContent(date))
                    )}
        </div>
    );
};

export default RowsWithTiles;
