import React, { useRef, useEffect, useState } from 'react';
import { ClientLayout } from '../models/ClientLayoutModel';
import { getDateRange, isBookingOnDate } from '../helpers/DateHelper';

interface ColumnsAndRowsProps {
    data: ClientLayout;
}

const BookingDaysColumns: React.FC<ColumnsAndRowsProps> = ({ data }) => {
    const [shouldAddEmptyRow, setShouldAddEmptyRow] = useState(false);
    const columnsContainerRef = useRef<HTMLDivElement | null>(null);

    const dateRange = getDateRange(data.dateRangeBeforeToday, data.dateRangeAfterToday);
    const evenOddStyle = (index: number) => (index % 2 === 0 ? JSON.parse(data.style.evensCss ?? "{}") : JSON.parse(data.style.oddsCss ?? "{}"));

    // Get today's date in YYYY-MM-DD format
    const today = new Date().toISOString().split('T')[0];

    useEffect(() => {
        const checkOverflow = () => {
            const container = columnsContainerRef.current;
            if (container && window.innerHeight > container.clientHeight) {
                setShouldAddEmptyRow(true);
            } else {
                setShouldAddEmptyRow(false);
            }
        };

        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => window.removeEventListener('resize', checkOverflow);
    }, []);

    return (
        <div className="columns-container" ref={columnsContainerRef} style={JSON.parse(data.style.backgroundCss ?? "{}")}>
            <div className="columns-header">
                <div className="header-item" style={JSON.parse(data.style.dayHeaderCss ?? "{}")}>
                    <div style={JSON.parse(data.style.dayHeaderDayOfWeekCss ?? "{}")}>{data.layoutName}</div>
                </div>
                {dateRange.map((date, index) => {
                    const dayOfWeekCapitalized =
                        new Date(date).toLocaleDateString('da-DK', { weekday: 'long' }).charAt(0).toUpperCase() +
                        new Date(date).toLocaleDateString('da-DK', { weekday: 'long' }).slice(1);
                    const dateFormatted = new Date(date).getDate() + '/' + (new Date(date).getMonth() + 1);

                    // Check if the date is today
                    const isToday = date === today;
                    const headerStyle = {
                        ...JSON.parse(data.style.dayHeaderCss ?? "{}"),
                        ...(isToday ? JSON.parse(data.style.todayCss ?? "{}") : {})
                    };

                    return (
                        <div key={date} className="header-item" style={headerStyle}>
                            <div className="column-header-day-of-week" style={JSON.parse(data.style.dayHeaderDayOfWeekCss ?? "{}")}>{dayOfWeekCapitalized}</div>
                            <div className="date-formatted" style={JSON.parse(data.style.dayHeaderDateCss ?? "{}")}>{dateFormatted}</div>
                        </div>
                    );
                })}
            </div>
            <div className="columns-body">
                {data.calendars.map((calendar) => (
                    <div key={calendar.id} className="columns-row">
                        <div className="calendar-title events-column" style={JSON.parse(data.style.calendarTitleCss ?? "{}")}>
                            {calendar.name}
                        </div>
                        {dateRange.map((date) => {
                            const bookingsForDate = data.bookings.filter(booking => booking.calendarId === calendar.id && isBookingOnDate(new Date(booking.start), new Date(booking.end), date))

                            const isToday = date === today;
                            const columnStyle = {
                                ...evenOddStyle(dateRange.indexOf(date)),
                                ...(isToday ? JSON.parse(data.style.todayCss ?? "{}") : {})
                            };

                            return (
                                <div key={date} className="events-column" style={columnStyle}>
                                    {bookingsForDate.length > 0 ? (
                                        bookingsForDate.map((booking) => {
                                            let startTime: Date, endTime: Date;

                                            const bookingStartDate = new Date(booking.start).toISOString().split('T')[0];
                                            const bookingEndDate = new Date(booking.end).toISOString().split('T')[0];

                                            // Apply the logic to determine the correct times
                                            if (bookingStartDate === date && bookingEndDate !== date) {
                                                // First day: use the actual start time and set the end time to 23:59
                                                startTime = new Date(booking.start);
                                                endTime = new Date(booking.start);
                                                endTime.setHours(23, 59);
                                            } else if (bookingStartDate !== date && bookingEndDate === date) {
                                                // Last day: set the start time to 00:00 and use the actual end time
                                                startTime = new Date(booking.end);
                                                startTime.setHours(0, 0);
                                                endTime = new Date(booking.end);
                                            } else if (bookingStartDate !== date && bookingEndDate !== date) {
                                                // Intermediate days: set start time to 00:00 and end time to 23:59
                                                startTime = new Date(date); // current date
                                                startTime.setHours(0, 0);
                                                endTime = new Date(date); // current date
                                                endTime.setHours(23, 59);
                                            } else {
                                                // Single day booking
                                                startTime = new Date(booking.start);
                                                endTime = new Date(booking.end);
                                            }

                                            return (
                                                <div key={booking.id} className="booking-event" style={JSON.parse(data.style.bookingContainerCss ?? "{}")}>
                                                    {data.showPersonOnBookings && (
                                                        <div className="booking-person" style={JSON.parse(data.style.bookingPersonCss ?? "{}")}>{booking.person}</div>
                                                    )}
                                                    <div className="booking-title" style={JSON.parse(data.style.bookingTitleCss ?? "{}")}>{booking.title}</div>
                                                    <div className="booking-time" style={JSON.parse(data.style.bookingTimeCss ?? "{}")}>
                                                        {startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - {endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className="booking-event" style={JSON.parse(data.style.bookingContainerCss ?? "{}")}>
                                            <div className="booking-time"></div>
                                            <div className="booking-title"></div>
                                            {data.showPersonOnBookings && (
                                                <div className="booking-person"></div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                ))}

                {shouldAddEmptyRow && (
                    <div className="columns-row empty-row">
                        <div className="calendar-title events-column" style={JSON.parse(data.style.calendarTitleCss ?? "{}")}></div>
                        {dateRange.map((date, index) => {
                            const isToday = new Date(date).toDateString() === new Date().toDateString();
                            return (
                                <div key={index} className="events-column" style={{
                                    ...evenOddStyle(index),
                                    ...(isToday ? JSON.parse(data.style.todayCss ?? "{}") : {})
                                }}>
                                </div>
                            );
                        })}
                    </div>
                )}

            </div>
        </div>
    );
};

export default BookingDaysColumns;
