import React, { useEffect, useState, useCallback, useRef } from "react";
import { ClientLayout, Booking } from "../models/ClientLayoutModel";
import { IntegrationType } from "../models/ModelTypes";

interface SingleEventViewProps {
    data: ClientLayout;
}

const SingleEvent: React.FC<SingleEventViewProps> = ({ data }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [mediaSrc, setMediaSrc] = useState<string | null>(null);
    const [mediaType, setMediaType] = useState<string | null>(null);
    const [truncatedDescription, setTruncatedDescription] = useState<string | null>(null);
    const booking: Booking | undefined = data.bookings[currentIndex];

    // Persistent media cache
    const mediaCache = useRef<{ [key: string]: { src: string; type: string } }>({});
    const descriptionRef = useRef<HTMLDivElement>(null);

    const preloadMedia = useCallback(async (index: number) => {
        const bookingToLoad = data.bookings[index];
        const mediaKey = bookingToLoad.externalImageUri;

        if (!mediaKey) {
            return null;
        }

        const relatedCalendar = data.calendars.find(
            (calendar) => calendar.id === bookingToLoad.calendarId
        );

        if (mediaCache.current[mediaKey]) {
            return mediaCache.current[mediaKey];
        }

        if (relatedCalendar?.calendarIntegrationType === IntegrationType.Relesys && relatedCalendar?.authTokenForExternalDataOfCalendar) {
            try {
                const response = await fetch(bookingToLoad.externalImageUri, {
                    headers: {
                        Authorization: `Bearer ${relatedCalendar.authTokenForExternalDataOfCalendar}`
                    }
                });

                if (response.ok) {
                    const contentType = response.headers.get("Content-Type");
                    const blob = await response.blob();
                    const objectURL = URL.createObjectURL(blob);

                    let mediaInfo: { src: string; type: string } | null = null;

                    if (contentType?.startsWith("image/")) {
                        mediaInfo = { src: objectURL, type: "image" };
                    } else if (contentType?.startsWith("video/")) {
                        mediaInfo = { src: objectURL, type: "video" };
                    } else {
                        return null;
                    }

                    mediaCache.current[mediaKey] = mediaInfo;
                    return mediaInfo;
                } else {
                    return null;
                }
            } catch (error) {
                return null;
            }
        } else {
            return null; //NYI
        }
    }, [data.bookings, data.calendars]);

    const handleOverflowCheck = useCallback(() => {
        if (descriptionRef.current && booking?.description) {
            const ellipsis = `<div style="font-weight:bold">[... ${data.style.overflowEllipsisPostfixString || ""}]</div>`;
            const originalHTML = booking.description;

            // Insert full HTML for initial overflow check
            descriptionRef.current.innerHTML = originalHTML;

            if (descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight) {
                const tempDiv = document.createElement("div");
                tempDiv.innerHTML = originalHTML;
                let truncatedHTML = "";
                let isOverflowing = false;

                // Loop through child nodes to add them without breaking tags
                for (let i = 0; i < tempDiv.childNodes.length && !isOverflowing; i++) {
                    const node = tempDiv.childNodes[i].cloneNode(true);

                    // Determine how to add the node based on its type
                    if (node.nodeType === Node.ELEMENT_NODE) {
                        // For elements, we will truncate the text content
                        const innerText = node.textContent ?? ""; // Get the text content of the element
                        let currentLength = 0;

                        // Loop through the inner text to find how much can be added without overflow
                        while (currentLength < innerText.length) {
                            const testContent = innerText.slice(0, currentLength + 1);
                            const testNode = document.createElement(node.nodeName); // Create a new element with the same tag name
                            testNode.textContent = testContent; // Set the test content

                            // Create the new HTML with the test node
                            descriptionRef.current.innerHTML = truncatedHTML + testNode.outerHTML + ellipsis;

                            // Check for overflow
                            if (descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight) {
                                isOverflowing = true;
                                break; // Stop if overflow occurs
                            }
                            currentLength++;
                        }

                        // Add only the text that fits into the truncatedHTML
                        truncatedHTML += `<${node.nodeName.toLowerCase()}>${innerText.slice(0, currentLength - 1)}</${node.nodeName.toLowerCase()}>`; // Use inner text that fits
                    } else if (node.nodeType === Node.TEXT_NODE) {
                        truncatedHTML += node.textContent; // Add text directly
                    }

                    // Update the real element to test overflow
                    descriptionRef.current.innerHTML = truncatedHTML + ellipsis;

                    // Check if overflow occurs after adding this node
                    if (descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight) {
                        isOverflowing = true;
                        break; // Stop if overflow occurs
                    }
                }

                // Set the final truncated HTML with ellipsis
                setTruncatedDescription(truncatedHTML + ellipsis);
            } else {
                setTruncatedDescription(originalHTML); // No truncation needed
            }
        }
    }, [booking, data.style.overflowEllipsisPostfixString]);

    useEffect(() => {
        if (booking) {
            preloadMedia(currentIndex).then(media => {
                if (media) {
                    setMediaSrc(media.src);
                    setMediaType(media.type);
                }
            });
            handleOverflowCheck();
        }
    }, [booking, currentIndex, preloadMedia, handleOverflowCheck]);

    useEffect(() => {
        if (booking) {
            // Load the first media
            preloadMedia(currentIndex).then(media => {
                if (media) {
                    setMediaSrc(media.src);
                    setMediaType(media.type);
                }
            });
            handleOverflowCheck();

            // Set the interval for subsequent events
            const interval = setInterval(async () => {
                const nextIndex = (currentIndex + 1) % data.bookings.length;
                const media = await preloadMedia(nextIndex);

                if (media) {
                    setMediaSrc(media.src);
                    setMediaType(media.type);
                    setCurrentIndex(nextIndex);
                } else {
                    // No media, proceed to the next event without media
                    setMediaSrc(null);
                    setMediaType(null);
                    setCurrentIndex(nextIndex);
                }
            }, data.style.eventDisplayDurationInSeconds ? data.style.eventDisplayDurationInSeconds * 1000 : 30000); // 30 seconds default interval

            return () => clearInterval(interval);
        }
    }, [booking, currentIndex, preloadMedia, handleOverflowCheck, data.bookings.length, data.style.eventDisplayDurationInSeconds]);



    if (!booking) {
        return <div>No booking available</div>;
    }

    return (
        <div className="event-details">
            {mediaSrc ? (
                mediaType === "image" ? (
                    <img
                        src={mediaSrc}
                        alt={booking.title}
                        style={data.style.eventMediaCss ? JSON.parse(data.style.eventMediaCss) : {}}
                    />
                ) : (
                    <video
                        src={mediaSrc}
                        autoPlay
                        muted
                        style={data.style.eventMediaCss ? JSON.parse(data.style.eventMediaCss) : {}}
                    />
                )
            ) : (
                <div
                    className="empty-media"
                    style={data.style.eventMediaCss ? JSON.parse(data.style.eventMediaCss) : {}}
                ></div>
            )}
            <div style={data.style.bookingTitleCss ? JSON.parse(data.style.bookingTitleCss) : {}}>
                {booking.title}
            </div>

            <div
                ref={descriptionRef}
                style={data.style.eventDescriptionCss ? JSON.parse(data.style.eventDescriptionCss) : {}}
                dangerouslySetInnerHTML={{ __html: truncatedDescription || booking.description }}
            />

            <div className='pagination-dots'>
                {data.bookings.map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${index === currentIndex ? "active" : ""}`}
                        style={index === currentIndex ? JSON.parse(data.style.eventPaginationActiveDotCss) : JSON.parse(data.style.eventPaginationDotCss)}
                    ></span>
                ))}
            </div>
        </div>
    );
};

export default SingleEvent;
