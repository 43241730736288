// BookingDaysRows.tsx
import React from 'react';
import { ClientLayout, Calendar, Booking } from '../models/ClientLayoutModel';
import { isBookingOnDate } from '../helpers/DateHelper';
import { getBookingsForDateSorted, getFieldValue } from '../helpers/Utils';

interface RowsWithTablesProps {
    data: ClientLayout;
    scrollRef: React.RefObject<HTMLDivElement>;
}


const RowsWithTables: React.FC<RowsWithTablesProps> = ({ data, scrollRef }) => {
    let dataIndex = -1;
    // Utility function to generate date range
    const getDateRange = (dateRangeBefore: number, dateRangeAfter: number): string[] => {
        const today = new Date();
        const startDate = new Date(today);
        startDate.setDate(today.getDate() - dateRangeBefore);
        const endDate = new Date(today);
        endDate.setDate(today.getDate() + dateRangeAfter);

        const dates: string[] = [];
        let currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            dates.push(currentDate.toISOString().split('T')[0]); // Format YYYY-MM-DD
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    };

    // Function to render calendar header based on rowHeaderDatasetType
    const renderHeader = (date: string) => {
        const dayOfWeekCapitalized =
            new Date(date).toLocaleDateString('da-DK', { weekday: 'long' }).charAt(0).toUpperCase() +
            new Date(date).toLocaleDateString('da-DK', { weekday: 'long' }).slice(1);
        const dateFormatted = new Date(date).getDate() + '/' + (new Date(date).getMonth() + 1);

        return (
            <div className="day-header" style={JSON.parse(data.style.dayHeaderCss ?? "{}")}>
                <div className="day-of-week" style={JSON.parse(data.style.dayHeaderDayOfWeekCss ?? "{}")}>
                    {dayOfWeekCapitalized}
                </div>
                <div className="date-formatted" style={JSON.parse(data.style.dayHeaderDateCss ?? "{}")}>
                    {dateFormatted}
                </div>
            </div>
        );
    };

    // Function to render booking content based on tileDatasetType
    const renderTableContent = (bookingsForDate: Booking[], calendarFromData?: Calendar) => {
        return (
            <div className="table-container" style={{}}>
                {/* Table Header */}
                {!data.style.mergeBookingsIntoSingleCalendarView && <div className="table-header" style={{}}>
                    {data.fieldNamesToDisplay.map((field) => (
                        <div key={field.sourceType + field.fieldName} className="table-header-cell" style={JSON.parse(field.headerFieldCss ?? "{}")}>
                            {field.displayName}
                        </div>
                    ))}
                </div>
                }
                {/* Table Content */}
                {bookingsForDate.map((booking) => {
                    // Increment the global index if mergeBookingsIntoSingleCalendarView is true
                    if (data.style?.mergeBookingsIntoSingleCalendarView) {
                        dataIndex++;
                    }

                    return (
                        <div
                            key={booking.id}
                            className="table-row"
                            style={{
                                ...((data.style?.mergeBookingsIntoSingleCalendarView
                                    ? dataIndex % 2 === 0 // Use global index if merging into a single view
                                    : (bookingsForDate.indexOf(booking) + 1) % 2 === 0) // Otherwise, use local index from map
                                    ? JSON.parse(data.style?.oddsCss ?? "{}")
                                    : JSON.parse(data.style?.evensCss ?? "{}"))
                            }}
                        >
                            {data.fieldNamesToDisplay.map((field) => (
                                <div key={field.sourceType + field.fieldName} className="table-cell" style={JSON.parse(field.valueFieldCss ?? "{}")}>
                                    {getFieldValue(
                                        booking,
                                        field,
                                        calendarFromData ?? data.calendars.find(c => c.id === booking.calendarId) ?? {} as Calendar // The last to satisfy the linter, should never happen
                                    )}
                                </div>
                            ))}
                        </div>
                    );
                })}

            </div>
        );
    };

    const renderCalendars = (calendar: Calendar) => {
        const dateRange = getDateRange(data.dateRangeBeforeToday, data.dateRangeAfterToday);

        // Check if there are any bookings for the entire date range for the calendar
        const hasBookings = dateRange.some((date) =>
            data.bookings.some((booking) =>
                booking.calendarId === calendar.id && isBookingOnDate(new Date(booking.start), new Date(booking.end), date)
            )
        );

        // If there are no bookings and we have a merged view, return null and skip rendering
        if (data.style.mergeBookingsIntoSingleCalendarView && !hasBookings) {
            return null;
        }

        return (
            <div key={calendar.id} className="rows-with-tables-container" style={JSON.parse(data.style.backgroundCss ?? "{}")}>
                {!data.style.mergeBookingsIntoSingleCalendarView && (
                    <div className="rows-with-tables-header" style={JSON.parse(data.style.calendarTitleCss ?? "{}")}>
                        {calendar.name}
                    </div>
                )}
                {dateRange.map((date) => {
                    const bookingsForDate = getBookingsForDateSorted(data.bookings, date, calendar)

                    return (
                        <div key={date} className="date-container">
                            {renderHeader(date)}
                            {bookingsForDate.length > 0 && renderTableContent(bookingsForDate, calendar)}
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderBookings = (bookings: Booking[]) => {
        const dateRange = getDateRange(data.dateRangeBeforeToday, data.dateRangeAfterToday);

        return (
            <div className="merged-bookings-container" style={JSON.parse(data.style.backgroundCss ?? "{}")}>
                {dateRange.map((date) => {
                    const bookingsForDateSorted = getBookingsForDateSorted(bookings, date)
                    return (
                        <div key={date} className="date-container">
                            {renderHeader(date)}
                            {bookingsForDateSorted.length > 0 ? renderTableContent(bookingsForDateSorted) : (
                                <div className="no-bookings">
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };




    return (
        <div className={data.style.autoscrollOnVerticalOverflow ? "scrollable-content" : "{}"} ref={scrollRef}>
            <div style={JSON.parse(data.style.layoutNameCss ?? "{}")}>{data.layoutName}</div>
            {data.style.mergeBookingsIntoSingleCalendarView && <div className="table-header" style={{}}>
                {data.fieldNamesToDisplay.map((field) => (
                    <div key={field.sourceType + field.fieldName} className="table-header-cell" style={JSON.parse(field.headerFieldCss ?? "{}")}>
                        {field.displayName}
                    </div>
                ))}
            </div>
            }
            {data && data.calendars.length > 0 && !data.style.mergeBookingsIntoSingleCalendarView ? (
                data.calendars.map((calendar) => renderCalendars(calendar))
            ) : data && data.style.mergeBookingsIntoSingleCalendarView ?
                renderBookings(data.bookings) :
                (
                    <div>No calendars available.</div>
                )}
        </div>
    );
};

export default RowsWithTables;
