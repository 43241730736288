import { Booking, Calendar, FieldNamesToDisplay } from "../models/ClientLayoutModel";
import { SourceType } from "../models/ModelTypes";
import { isBookingOnDate } from "./DateHelper";

let refreshId: NodeJS.Timeout;

export const refreshAt = (hours: number, minutes: number, seconds: number) => {
    if (refreshId)
        return;
    var now = new Date();
    var then = new Date();

    if (now.getHours() > hours ||
        (now.getHours() === hours && now.getMinutes() > minutes) ||
        (now.getHours() === hours && now.getMinutes() === minutes && now.getSeconds() >= seconds)) {
        then.setDate(now.getDate() + 1);
    }
    then.setHours(hours);
    then.setMinutes(minutes);
    then.setSeconds(seconds);

    var timeout = (then.getTime() - now.getTime());
    refreshId = setTimeout(function () { window.location.reload(); }, timeout);
}

export const cancelRefreshAt = () => {
    if (refreshId)
        clearTimeout(refreshId)
}

export const getFieldValue = (booking: Booking, field: FieldNamesToDisplay, calendar: Calendar): string => {
    switch (field.sourceType) {
        case SourceType.Calendar:
            switch (field.fieldName) {
                case "Title":
                    return calendar.name;
                default:
                    return "N/A";
            }
        case SourceType.CustomCalendarField:
            const calendarField = calendar.customFields.find((f) => f.name === field.fieldName);
            return calendarField ? calendarField.value ?? "N/A" : "N/A";
        case SourceType.Booking:
            switch (field.fieldName) {
                case "Title":
                    return booking.title;
                case "Time":
                    var startTime = new Date(booking.start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
                    var endTime = new Date(booking.end).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
                    return startTime + " - " + endTime
                case "Person":
                    return booking.person ?? "";
                default:
                    return "N/A";
            }
        case SourceType.CustomBookingField:
            const bookingField = booking.customFields.find((f) => f.name === field.fieldName);
            return bookingField?.value ?? "";
        default:
            return "N/A";
    }
};

export const getBookingsForDateSorted = (bookings: Booking[], date: string, calendar: Calendar | undefined = undefined) => {
    const currentDate = new Date();
    const isToday = new Date(date).toDateString() === currentDate.toDateString();

    const filteredBookings = bookings.filter((booking) => {
        const isRelatedToCalendarIfExist_OtherwiseTrue = calendar ? booking.calendarId === calendar.id : true;

        const bookingStart = new Date(booking.start);
        const bookingEnd = new Date(booking.end);

        // Only include bookings on the specified date and not yet ended if it's today
        const isOnDate = isBookingOnDate(bookingStart, bookingEnd, date);
        const isTodayAndHasNotEnded_OtherwiseTrue = isToday ? bookingEnd > currentDate : true;

        return isOnDate && isTodayAndHasNotEnded_OtherwiseTrue && isRelatedToCalendarIfExist_OtherwiseTrue;
    });

    filteredBookings.sort((a: Booking, b: Booking) => {
        const startDiff = new Date(a.start).getTime() - new Date(b.start).getTime();
        if (startDiff !== 0) {
            return startDiff; // If `start` times are different, sort by `start`
        }
        // If `start` times are the same, sort by `end` time
        return new Date(a.end).getTime() - new Date(b.end).getTime();
    });

    return filteredBookings;
};

